<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="{spf: form.spf}"
                               @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="onAddNewCat" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(name)="data">
                    <div
                        class="column-width-100 bp-category-name">
                        <div>{{ data.item['name_' + lng] }} </div>
                        <i 
                            v-if="variantAttribute"
                            class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                            @click="onEditCatName(data.item)"
                        ></i>
                    </div>
                </template>
                <template #cell(cost_amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  v-model="data.item.cost_amount"
                                  :disabled="nameIsEmpty(data.item, ['name_ru', 'name_kk'])"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'cost_amount', data.item.cost_amount, 2, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost_amount === null || data.item.cost_amount === '' || data.item.cost_amount <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="2">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="budgetForm"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addItem"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from '@/modules/budget-request/components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "../mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from '../components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "../components/forms-download-reprt.vue";
import CSetBFCategoryName from "../components/c-set-bf-category-name.vue";
import CBudgetFormsDataTransfer from '../components/budget-forms-data-transfer.vue';

export default {
    name: 'Form813',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        BreadcrumbsFilter, 
        FormsDownloadReprt, 
        ModalFilesManagementNodecode,
        CBudgetFormsCopyData, 
        CSetBFCategoryName,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            defaultParams: {
                gr: 13,
                abp: 256,
                prg: 96,
                ppr: 15
            },
            form: {
                code: '813',
                name_ru: 'Расчет расходов на выполнение государственных обязательств по компенсации операционных (эксплуатационных) затрат по проектам государственно-частного партнерства',
                name_kk: 'Мемлекеттік-жеке серіктестік жобалары боюнча операциялық (операциялық) шығындарды орындау жөніндегі мемлекеттік міндеттемелерді орындау шығындарын есептеу',
                spf: {
                    name: '813-Выполнение государственных обязательств по компенсации операционных (эксплуатационных) затрат по проектам государственно-частного партнерства',
                    spf: '813'
                }
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            categoryFieldNames: {ru: "name_ru", kk: "name_kk"}
        };
    },
    methods: {
        addItem(nameRu, nameKk) {
            const item = { 
                id: this.newRowStartId,
                name_ru: nameRu,
                name_kk: nameKk
            };
            this.newRowStartId--;
            this.itemUpdate(item);
            this.budgetForm.push(item);
        },
        async itemUpdate(item) {
            this.$set(item, 'cost_amount', 0);
            this.$set(item, 'files', 0);
            this.$set(item, 'itemToDelete', false);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        calcTotal(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_amount', ids));
        },

        input12(item, field, value) {
            if (parseInt(value) > 12) {
                this.$set(item, field, 0);
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('not_more_12'));
            } else {
                this.$set(item, field, value);
            }
        }, // проверка value>12

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const values = await response.json();

                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        name_ru: val.name_ru,
                        name_kk: val.name_kk,
                        files: val.files,
                        row_files: val.row_files,
                        cost_amount: val.cost_amount,
                        itemToDelete: false,
                    };

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        noAbc: function (event) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                if (this.isSavingForbidden(row, this.tableFields, ['cost_amount'], [])) return;
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'name_ru', row.name_ru);
                    this.$set(item, 'name_kk', row.name_kk);
                    this.$set(item, 'cost_amount', parseFloat(row.cost_amount));
                    this.$set(item, 'row_files', row.row_files);
                    values.push(item);
            }
            if (values.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    this.getCommonText('on_save'),
                    {
                        title: this.getCommonText('confirm'),
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: this.getCommonText('yes'),
                        cancelTitle: this.getCommonText('cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                            this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}!`);
                        }
                        else {
                            if (value && this.variantAttribute) {
                                this.save(values);
                            }
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', this.getErrText('saving_error'), error.toString());
                    });
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-br-two-column-form-with-row-attach-files/'
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_amount);
            }
            return Math.ceil(sum);
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'cost_amount',
                        label: this.getCommonText('total_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .filter-actions-flex {
        display: flex;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
</style>